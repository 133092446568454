export default {
    "收益率": "Earnings Rate",
    "结束时间": "End Time",
    "短期融资": "Short-term financing",
    "记录": "Record",
    "转出本金": "Transfer of principal",
    "转出收益": "Transfer out income",
    "钱包余额": "Wallet balance",
    "天": "Days",
    "请输入用户名": "Please enter username",
    "请输入密码": "Please enter password",
    "提交": "Submit",
    "立即开户": "Open account now",
    "联系客服": "Contact customer service",
    "用户名": "Username",
    "密码": "Password",
    "确认密码": "Confirm password",
    "请确认密码": "Please confirm password",
    "邀请码": "Invitation code",
    "请输入邀请码": "Please enter invitation code",
    "真是姓名(可选)": "Real name (optional)",
    "身份证号码(可选)": "ID number (optional)",
    "已经有一个帐户": "Already have an account",
    "版权所有©2024TS股份有限公司保留所有权利": "Copyright ©2024 TS Co., Ltd. All rights reserved",
    "此网站支持IPv6访问": "This website supports IPv6 access",
    "在线用户": "Online users",
    "快速入口": "Quick entry",
    "支持全球USDT输入": "Supports global USDT input",
    "关于我们": "About us",
    "帮助中心": "Help center",
    "市场": "Market",
    "产品趋势": "Product trends",
    "订单历史": "Order history",
    "我的账户": "My account",
    "持仓": "Positions",
    "今日开业": "Today's opening",
    "昨天收盘": "Yesterday's close",
    "最高": "Highest",
    "最低": "Lowest",
    "K线": "K-line",
    "趋势": "Trend",
    "挂单": "Pending orders",
    "数量": "Amount",
    "买涨价": "Buy up price",
    "买跌价": "Buy down price",
    "时间": "Time",
    "去向": "Direction",
    "买涨": "Buy up",
    "买跌": "Buy down",
    "持仓详情": "Position details",
    "结算时间": "Settlement time",
    "购买时间": "Purchase time",
    "盈利": "Profit",
    "没有更多数据": "No more data",
    "购买确认": "Purchase confirmation",
    "名称": "Name",
    "方向": "Direction",
    "当前价格": "Current price",
    "账户余额": "Account balance",
    "秒": "Seconds",
    "最小购买金额等于或大于": "Minimum purchase amount equal to or greater than",
    "收益": "Earnings",
    "取消": "Cancel",
    "确定": "Confirm",
    "持仓中": "In position",
    "历史持仓": "Historical positions",
    "利润/损失/时间": "Profit/Loss/Time",
    "产品/金额": "Product/Amount",
    "加载中": "Loading",
    "充值": "Deposit",
    "提款": "Withdraw",
    "财务详细信息": "Financial details",
    "实名认证": "Real name authentication",
    "提款历史记录": "Withdrawal history",
    "订单历史记录": "Order history",
    "我的团队": "My team",
    "邀请": "Invite",
    "用户等级": "User level",
    "杠杆作用": "Leverage",
    "修改密码": "Change password",
    "切换语言": "Switch language",
    "退出登录": "Logout",
    "存入市场": "Deposit to market",
    "充值数量": "Deposit amount",
    "登录密码": "Login password",
    "提款密码": "Withdrawal password",
    "首次设置时，请输入4位提款密码": "Please enter a 4-digit withdrawal password for the first time",
    "请重新输入4位数字密码": "Please re-enter the 4-digit numeric password",
    "新密码": "New password",
    "请仔细检查您的信息": "Please carefully check your information",
    "银行名称": "Bank name",
    "银行代码": "Bank code",
    "银行账户": "Bank account",
    "持卡人": "Cardholder",
    "银行分行": "Bank branch",
    "提款数量": "Withdrawal amount",
    "请输入提款数量": "Please enter withdrawal amount",
    "友情提示": "Friendly reminder",
    "印花税": "Stamp duty",
    "如果你有任何问题，请": "If you have any questions, please",
    "时间": "Time",
    "过滤": "Filter",
    "开始": "Start",
    "结束": "End",
    "请选择时间": "Please select time",
    "交易类型": "Transaction type",
    "投资结算": "Investment settlement",
    "在线充值": "Online deposit",
    "提款退款": "Withdrawal refund",
    "投资支出": "Investment expenditure",
    "提款申请": "Withdrawal request",
    "提款成功": "Withdrawal successful",
    "重置": "Reset",
    "实名制验证": "Real-name verification",
    "匿名用户": "Anonymous user",
    "真实姓名": "Real name",
    "请输入真实姓名": "Please enter real name",
    "身份证号码": "ID number",
    "请输入身份证号码": "Please enter ID number",
    "邀请二维码": "Invitation QR code",
    "复制邀请链接": "Copy invitation link",
    "复制成功": "Copy successful",
    "下属人数": "Number of subordinates",
    "团队规模": "Team size",
    "团队余额": "Team balance",
    "团队帐户更改": "Team account change",
    "团队投资": "Team investment",
    "团队报告": "Team report",
    "状态": "Status",
    "未解决": "Unresolved",
    "稳定的": "Stable",
    "概述": "Overview",
    "总余额": "Total balance",
    "投资": "Investment",
    "盈利能力": "Profitability",
    "存取款差异": "Deposit/withdrawal difference",
    "总损益": "Total profit/loss",
    "退出成功": "Logout successful",
    "请先完成实名认证": "Please complete real-name verification first",
    "绑定银行卡": "Bind bank card",
    "请输入4位数字密码以验证您的身份": "Please enter a 4-digit numeric password to verify your identity",
    "请绑定银行卡": "Please bind a bank card",
    "收入": "Income",
    "支出": "Expenditure",
    "价格": "Price",
    "此功能尚未打开": "This function is not yet available",
    "固定": "Fixed",
    "操作": "Operation",
    "详情": "Details",
    "复制失败": "Copy failed",
    "切换成功": "Switch successful",
    "网上借款": "Online borrowing",
    "公告": "Notice",
    "银行卡": "Bank Card",
    "请选择银行": "Please select a bank",
    "请填写充值金额": "Please fill in the recharge amount",
    "提款地址": "Withdrawal address",
    "请输入提款地址": "Please enter the withdrawal address",
    "您还未绑定提款地址，请先绑定提款地址!": "You have not yet bound a withdrawal address, please bind a withdrawal address first!",
    "USDT地址": "USDT address",
    "应转USDT金额": "Amount to be converted to USDT",
    "客服": "Service",
    "请先设置提款密码": "Please set a withdrawal password first",
    "余额": "Balance",
    "格式不正确": "Incorrect format",
    "取款支出": "Withdrawal expenses",
    "取款退回": "Withdrawal refund",
    "充值成功": "Recharge successful",
    "上次交易": "Last transaction",
    "APP下载": "APP Download",
    "佣金": "commission",
    "账户余额R50000以下，1:1配比": "Account balance below R50000, 1:1 ratio",
    "账户余额R50001-R100000，1:2配比": "Account balance R50001-R100000, 1:2 ratio",
    "账户余额R100001-R200000，1:3配比": "Account balance R100001-R200000, 1:3 ratio",
    "账户余额R200001-R500000，1:4配比": "Account balance R200001-R500000, 1:4 ratio",
    "账户余额R500000以上，1:5配比": "Account balance above R500000, 1:5 ratio",
    "比如你的账户有R100000，那么平台会按照1:2的比例给你账户充值R200000，那么你的投资本金即为R300000": "For example, if your account has R100000, the platform will recharge R200000 to your account in a ratio of 1:2, and your investment principal will be R300000",
    "完成45天的合约后，你在归还平台配比本金的同时，还需要支付10%的盈利佣金": "After completing the 45 day contract, you need to pay a 10% commission on profits while returning the platform's matching principal",
    "当账户余额亏损至本金的30%，那么系统将自动解除合约": "When the account balance loses 30% of the principal, the system will automatically terminate the contract",
    "说明": "Describe",
    "点击参与": "Click to participate",
    "参与成功": "Successful participation",
    "已参与": "Already participated",
    "正面": "Front",
    "反面": "Back",
    "请上传证件": "Please upload your identification documents"
}